import React, { useState } from 'react';

import TransactionPopUpComponent from '../transactions/TransactionPopUpComponent'

const TopUpBalance = () => {
    const [currentStep, setCurrentStep] = useState(1);

    const handleFirstContinueClick = () => {
        setCurrentStep(2);
    };

    const handleSecondContinueClick = () => {
        setCurrentStep(3);
    };
    const getStepClass = (step) => {
        return currentStep === step ? 'h2-top-up-balance-h2 active-step' : 'h2-top-up-balance-h2';
    };

    const isFirstBlockDisabled = currentStep >= 2;
    const isSecondBlockDisabled = currentStep >= 3;

    return (
        <div>
            {/* <TransactionPopUpComponent /> */}
            <h1 className='h1-statistic-account'>Top up account and withdraw funds</h1>
            <main>
                <section className='h2-top-up-balance'>
                    <h2 className={getStepClass(1)}>Main information</h2>
                    <h2 className={getStepClass(2)}>Transfer details</h2>
                    <h2 className={getStepClass(3)}>Data confirmation</h2>
                </section>
                <section style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                    <fieldset disabled={isFirstBlockDisabled} className='top-up-fieldset'>
                        <section className='top-up-balance-inputs'>
                            <div>
                                <label>Transaction type</label>
                                <select>
                                    <option>Top Up Account</option>
                                    <option>Withdraw Funds</option>
                                    <option>Transfer between accounts</option>
                                </select>
                            </div>
                            <div>
                                <label>Source of funds</label>
                                <select>
                                    <option>Referal balance</option>
                                    <option>Invest balance</option>
                                </select>
                            </div>
                            <div>
                                <label>Destination of funds</label>
                                <input></input>
                            </div>
                            {currentStep == 1 && (<button onClick={handleFirstContinueClick} disabled={isFirstBlockDisabled}>Continue</button>)}

                        </section>
                    </fieldset>
                    {currentStep >= 2 && (
                        <fieldset disabled={isSecondBlockDisabled} className='top-up-fieldset'>
                            <section className='top-up-balance-inputs'>
                                <div>
                                    <label>Transfer amount</label>
                                    <select>
                                        <option>Top Up Account</option>
                                        <option>Withdraw Funds</option>
                                        <option>Transfer between accounts</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Currency</label>
                                    <select>
                                        <option>Total balance</option>
                                        <option>Withdraw Funds</option>
                                        <option>Invest balance</option>
                                    </select>
                                    <p> &bull; Сборы: Мы взимаем фиксированную плату в размере €/$3 за любой депозит менее €/$30.<br />
                                        &bull; Комиссия: отсутствует<br />
                                        &bull; Конвертация: отсутствует</p>
                                </div>
                                <div>
                                    <label>Currency to be credited</label>
                                    <select>
                                        <option>Total balance</option>
                                        <option>Withdraw Funds</option>
                                        <option>Invest balance</option>
                                    </select>
                                    <p>&bull;Сейчас Вы будете перенаправлены на сайт внешней платежной системы. Пожалуйста, строго придерживайтесь приведенных инструкций и убедитесь, что Ваш платеж равен или превышает поддерживаемую минимальную сумму, принимая во внимание комиссию сети блокчейн.</p>
                                </div>
                                {currentStep == 2 && (<button onClick={handleSecondContinueClick}>Continue</button>)}
                            </section>
                        </fieldset>
                    )}
                </section>
            </main>
        </div>
    );
};

export default TopUpBalance;